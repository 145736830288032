// Food Items array
const foodItems = [
    {
      label: 'starters',
      description: '',
      url: '/menu/alacarte/starters',
      imgSrc: '/assets/img/foods/starters.jpg',
      menuItems: [
        { name: 'Chicken Gyoza', description: '', price: 6.50, ingredients: '5pc, cabbage, chicken, garlic, gyoza sauce' },
        { name: 'Yasai Gyoza', description: '', price: 5.50, ingredients: '5pc, cabbage, soya, garlic, gyoza sauce' },
        { name: 'Duck Gyoza', description: '', price: 7.50, ingredients: '5pc, cabbage, duck, garlic, gyoza sauce' },
        { name: 'Prawn Tempura', description: '', price: 9.20, ingredients: '5 pc, deep fried prawn, dashi soy sauce, grated daikon, ginger' },
        { name: 'Yasai Veg Tempura', description: '', price: 6.10, ingredients: '5 pc, mix vegetables, dashi soy sauce, grated daikon, ginger' },
        { name: 'Salmon Tempura', description: '', price: 8.50, ingredients: '5 pc, deep fried fresh salmon, soy sauce, grated daikon, ginger' },
        { name: 'Edamame', description: '(GF)(VG)', price: 5.20, ingredients: 'Japanese soybeans, rock salt' },
        { name: 'Ebi Katsu', description: '', price: 6.80, ingredients: '3 pc, deep fried king prawn, spicy truffle mayo, spring onion' },
        { name: 'Miso Soup', description: '(GF)', price: 3.50, ingredients: 'tofu, spring onions, seaweed' },
        { name: 'Chicken Karaage', description: '', price: 6.50, ingredients: '5 pc, marinated chicken in garlic and ginger, deep fried served with lime and spicy truffle mayo' },
        { name: 'Takoyaki', description: '', price: 9.00, ingredients: '5 pc, deep fried octopus balls, mayo, tonkatsu sauce, bonito flakes, seaweed flakes' },
      ]
    },
    {
      label: 'sushi salad',
      description: '',
      url: '/menu/alacarte/sushisalad',
      imgSrc: '/assets/img/foods/pokebowls.jpg',
      menuItems: [
        { name: 'Wakame Salad', description: '(V)', price: 12.50, ingredients: '' },
      ]
    },
    {
      label: 'poke bowls',
      description: '',
      url: '/menu/alacarte/pokebowls',
      imgSrc: '/assets/img/foods/pokebowls.jpg',
      menuItems: [
        { name: 'Salmon Poke Bowl', description: '', price: 12.50, ingredients: 'fresh diced salmon, avocado, pickled cucumber, mixed salad, mooli, onion, sushi rice, homemade sesame dressing' },
        { name: 'Tuna Poke Bowl', description: '', price: 14.50, ingredients: 'fresh diced tuna, avocado, carrots, pickled cucumber, mixed salad, mooli, onion, sushi rice, homemade sesame dressing' },
        { name: 'Seaweed Poke Bowl', description: '', price: 9.50, ingredients: 'kaiso salad, tofu, pickled cucumber, mixed salad, mooli, onion, sushi rice, homemade sesame dressing' },
      ]
    },
    {
      label: 'rice dishes',
      description: '',
      url: '/menu/alacarte/ricedishes',
      imgSrc: '/assets/img/foods/ricedishes.jpg',
      menuItems: [
        { name: 'Chicken Katsu Curry', description: '', price: 10.50, ingredients: 'plain steamed rice, japanese curry sauce, dry onion, sesame seeds, micro herbs, chicken katsu' },
        { name: 'Prawn Katsu Curry', description: '', price: 12.50, ingredients: 'plain steamed rice, japanese curry sauce, dry onion, sesame seeds, micro herbs, prawn katsu' },
        { name: 'Veg Katsu Curry', description: '', price: 10.50, ingredients: 'plain steamed rice, japanese curry sauce, dry onion, sesame seeds, micro herbs, vegetable katsu' },
        { name: 'Salmon Teriyaki', description: '', price: 12.50, ingredients: 'fried salmon, steamed broccoli, plain steamed rice, teriyaki sauce' },
      ]
    },
    {
      label: 'sashimi',
      description: '3 pcs of sashimi served raw',
      url: '/menu/alacarte/sashimi',
      imgSrc: '/assets/img/foods/sashimi.jpg',
      menuItems: [
        { name: 'Salmon Sashimi', description: '(GF)', price: 5.00, ingredients: '' },
        { name: 'Tuna Sashimi', description: '(GF)', price: 5.80, ingredients: '' },
        { name: 'Sea Bass Sashimi', description: '(GF)', price: 5.50, ingredients: '' },
      ]
    },
    {
      label: 'nigiri',
      description: 'slices of toppings on a pillow of rice',
      url: '/menu/alacarte/nigiri',
      imgSrc: '/assets/img/foods/nigiri.jpg',
      menuItems: [
        { name: 'Salmon Nigiri', description: '(GF)', price: 4.50, ingredients: '' },
        { name: 'Tuna Nigiri', description: '(GF)', price: 5.50, ingredients: '' },
        { name: 'Sea Bass Nigiri', description: '(GF)', price: 5.50, ingredients: '' },
        { name: 'Cooked Prawn Nigiri', description: '', price: 4.50, ingredients: '' },
        { name: 'Prawn Tempura Nigiri', description: '(GF)', price: 7.50, ingredients: '' },
        { name: 'Inari Nigiri', description: '(V)', price: 4.80, ingredients: '' },
        { name: 'Avocado Mayo Nigiri', description: '(V)', price: 3.80, ingredients: '' },
      ]
    },
    {
      label: 'temaki',
      description: 'a traditional Japanese hand roll w/ rice & fillings',
      url: '/menu/alacarte/temaki',
      imgSrc: '/assets/img/foods/temaki.jpg',
      menuItems: [
        { name: 'Salmon Temaki', description: '', price: 6.80, ingredients: 'seaweed cone stuffed with fresh salmon' },
        { name: 'Tuna Temaki', description: '', price: 7.80, ingredients: 'seaweed cone stuffed w/ fresh tuna, avocado' },
        { name: 'Prawn Katsu Temaki', description: '', price: 6.80, ingredients: 'seaweed cone stuffed w/ crispy prawns, avocado, cream cheese, spicy truffle mayo' },
        { name: 'Salmon Philadelphia Temaki', description: '', price: 6.80, ingredients: 'seaweed cone stuffed w/ fresh salmon, cream cheese' },
        { name: 'Softshell Crab Tempura Temaki', description: '', price: 7.80, ingredients: 'seaweed cone stuffed w/ softshell crab tempura, mixed salad, spicy truffle mayo' },
        { name: 'Prawn Tempura Temaki', description: '', price: 5.80, ingredients: 'prawn tempura, avocado, mixed salad, sesame seeds' },
        { name: 'Yasai Temaki', description: '', price: 5.80, ingredients: 'inari, avocado, mango, cucumber, mixed salad' },
      ]
    },
    {
      label: 'hosomaki',
      description: 'a variety of fillings & thin rolls of rice w/ seaweed on the outside, 8 PC',
      url: '/menu/alacarte/hosomaki',
      imgSrc: '/assets/img/foods/hosomaki.jpg',
      menuItems: [
        { name: 'Tuna Hosomaki', description: '(GF)', price: 7.00, ingredients: '' },
        { name: 'Salmon Hosomaki', description: '(GF)', price: 6.80, ingredients: '' },
        { name: 'Prawn Hosomaki', description: '(GF)', price: 6.50, ingredients: '' },
        { name: 'Sea Bass Hosomaki', description: '(GF)', price: 6.50, ingredients: '' },
        { name: 'Unagi Hosomaki', description: '(GF)', price: 7.10, ingredients: '' },
        { name: 'Cucumber Hosomaki', description: '(VG, GF)', price: 4.50, ingredients: '' },
        { name: 'Avocado Hosomaki', description: '(VG, GF)', price: 4.80, ingredients: '' },
      ]
    },
    {
      label: 'hot sushi roll',
      description: '6 pc of hot sushi roll',
      url: '/menu/alacarte/hotsushiroll',
      imgSrc: '/assets/img/foods/hotsushiroll.jpg',
      menuItems: [
        { name: 'Hot Philadelphia Roll', description: '', price: 8.50, ingredients: 'salmon, cucumber, tobiko, cream cheese, seasonal fruit, truffle mayo, teriyaki sauce' },
        { name: 'Hot Tiger Roll', description: '', price: 8.50, ingredients: 'tempura roll, salmon, nigiri prawn, cream cheese, truffle mayo, teriyaki sauce' },
        { name: 'Hot Tuna Roll', description: '', price: 8.50, ingredients: 'tuna, cucumber, spicy mayo, teriyaki sauce' },
        { name: 'Crepe Roll', description: '', price: 9.50, ingredients: 'salmon, prawn katsu, tobiko, cream cheese, truffle mayo, teriyaki sauce' },
        { name: 'Tuna Maki Tempura', description: '', price: 6.90, ingredients: 'tempura tuna, truffle mayo, chilli powder'},
        { name: 'Salmon Maki Tempura', description: '', price: 6.50, ingredients: 'tempura salmon, truffle mayo, chilli powder'},
      ]
    },
    {
      label: 'iso uramaki roll',
      description: 'a layer of rice on the outside and a sheet of nori on the inside & fresh variety of filings',
      url: '/menu/alacarte/isouramakiroll',
      imgSrc: '/assets/img/foods/iso-uramaki.jpg',
      menuItems: [
        { name: 'California Uramaki', description: '', price: 8.50, ingredients: 'assorted fish, avocado, cucumber, sesame seeds, tobiko orange' },
        { name: 'Ebi Katsu Uramaki', description: '', price: 9.80, ingredients: 'prawn katsu, avocado, cream cheese, orange masago, teriyaki sauce' },
        { name: 'Alaskan Uramaki', description: '', price: 9.30, ingredients: 'fresh salmon, avocado, cream cheese, sesame seeds' },
        { name: 'Salmon Philadelphia Uramaki', description: '', price: 8.50, ingredients: 'fresh salmon, masago, sesame seeds' },
        { name: 'Spicy Tuna Cucumber Uramaki', description: '', price: 8.90, ingredients: 'tuna marinated w/ srirach chilli, cucumber, masago, spicy mayo' },
        { name: 'Tuna Avocado Uramaki', description: '', price: 8.90, ingredients: 'fresh tuna, avocado, sesame seeds, tobiko orange' },
        { name: 'Tsukiji Uramaki', description: '', price: 7.80, ingredients: 'fresh salmon, cucumber, masago, sesame seeds, tobiko orange' },
        { name: 'Vegan Uramaki', description: '(V)', price: 7.50, ingredients: 'cucumber, mango, marinated pumpkin, sesame seeds' },
      ]
    },
    {
      label: 'tsukiji special',
      description: '',
      url: '/menu/alacarte/tsukijispecial',
      imgSrc: '/assets/img/foods/tsukiji-special.jpg',
      menuItems: [
        { name: 'Green Dragon Roll', description: '', price: 13.50, ingredients: 'sushi rice, prawn tempura, avocado, truffle mayo, raw massago' },
        { name: 'Spicy Tuna Dragon Roll', description: '', price: 14.90, ingredients: 'sushi rice, cucumber, tuna, kimchi sauce, sesame seeds, prawn tempura, sriracha sauce, tempura super crunch' },
        { name: 'Spicy Salmon Dragon Roll', description: '', price: 14.60, ingredients: 'sushi rice, salmon, cucumber, kimchi sauce, sesame seeds, prawn tempura' },
        { name: 'Salmon Skin Dragon Roll', description: '', price: 14.60, ingredients: 'sushi rice, salmon skin, cucumber, glazed soy sauce, salmon egg, prawn tempura' },
        { name: 'Chicken Katsu Dragon Roll', description: '', price: 14.50, ingredients: 'sushi rice, chicken katsu, avocado, cucumber, spicy truffle mayo' },
        { name: 'Spicy Tuna Lava Roll', description: '', price: 13.90, ingredients: 'sushi rice, tuna, cucumber, tuna tartare, spicy truffle mayo, sweet potato, glazed soy sauce' },
        { name: 'Spicy Salmon Lava Roll', description: '', price: 13.60, ingredients: 'sushi rice, salmon, sriracha sauce, spring onion, cucumber, tartare salmon, prawn tempura' },
        { name: 'Spider Salmon Dragon Roll', description: '', price: 15.60, ingredients: 'sushi rice, softshell crab tempura, salmon, cucumber, spicy truffle mayo, teriyaki sauce, spring onion' },
        { name: 'Spider Tuna Dragon Roll', description: '', price: 15.50, ingredients: 'sushi rice, softshell crab tempura, salmon, cucumber, spicy truffle mayo, teriyaki sauce, spring onion' },
        { name: 'Tsukiji Special Dragon Roll', description: '', price: 14.80, ingredients: 'sushi rice, prawn katsu, avocado, salmon nigiri, garlic, glazed soy sauce, sesame seed, spring onion' },
        { name: 'Rainbow Dragon Roll', description: '', price: 15.50, ingredients: 'sushi rice, prawn tempura, avocado, salmon, tuna, truffle mayo, teriyaki sauce, fried sweet potato' },
        { name: 'Red Dragon Roll', description: '', price: 14.90, ingredients: 'sushi rice, prawn tempura, avocado, tuna, spicy truffle mayo, teriyaki sauce, fried sweet potato' },
        { name: 'Sweet Inari Roll', description: '', price: 10.80, ingredients: 'sushi rice, sweet potato tempura, inari tofu, teriyaki sauce, wakame goma salad' },
        { name: 'Green Vegan Dragon Roll', description: '', price: 10.80, ingredients: 'sushi rice, sweet potato tempura, avocado, truffle mayo' },
        { name: 'Volcano Roll', description: '', price: 10.80, ingredients: 'sushi rice, prawn tempura, salmon, raw massago, salmon tartare, spring onion' },
      ]
    },
    {
      label: 'sushi platter',
      description: 'platters to share',
      url: '/menu/alacarte/sushiplatter',
      imgSrc: '/assets/img/foods/sushi-platter.jpg',
      menuItems: [
        { name: 'Salmon Lover', description: '', price: 20.50, ingredients: '15 total pc, tempura maki (6pc), salmon nigiri (3pc), salmon sashimi (6pc)' },
        { name: 'Tuna Lover', description: '', price: 22.50, ingredients: '15 total pc, tempura maki & tuna temaki avocado (6pc), tuna nigiri (3pc), tuna sashimi (6pc)' },
        { name: 'Tsukiji Maki Set', description: '', price: 30.00, ingredients: '30 total pc, green dragon rolls, salmon tempura maki, california uramaki, salmon nigiri' },
        { name: 'Tokyo F/Style Platter', description: '', price: 24.50, ingredients: '16 total pc, gunkan special (2pc), nigiri (3pc), special dragin rolls (6pc), assorted sashimi (5pc)' },
        { name: 'Tsukiji F/Style Platter', description: '', price: 46.00, ingredients: '36 total pc, gunkan special (4pc), nigiri (4pc), tempura maki (8pc), special dragon rolls (8pc), mixed sashimi (12pc)' },
        { name: 'Osaka F/Style Platter', description: '', price: 95.00, ingredients: '70 total pc, uramaki, hosomaki, gunkan special, nigiri, tempura maki, hot sushi tiger roll, special rolls, mixed sashimi' },
        { name: 'Veg Freestyle Platter', description: '', price: 24.00, ingredients: '16 total pc, special dragon rolls (6pc), avocado tempura (6pc), gunkan (2pc), nigiri (2pc)' },
      ]
    },
    {
      label: 'ceviche',
      description: '',
      url: '/menu/alacarte/ceviche',
      imgSrc: '/assets/img/foods/ceviche.jpg',
      menuItems: [
        { name: 'Salmon Ceviche', description: '', price: 10.80, ingredients: 'raw salmon, lemon juice, soy sauce, kimchi sauce, ponzu sauce, red & yellow pepper, fresh chillies, spring onion' },
        { name: 'Tuna Ceviche', description: '', price: 12.80, ingredients: 'raw tuna, lemon juice, soy sauce, kimchi sauce, ponzu sauce, red & yellow pepper, fresh chillies, spring onion' },
        { name: 'Sea Bass Ceviche', description: '', price: 14.80, ingredients: 'raw sea bass, lemon juice, soy sauce, kimchi sauce, ponzu sauce, red & yellow pepper, fresh chillies, spring onion' },
      ]
    },
    {
      label: 'tartare',
      description: '',
      url: '/menu/alacarte/tartare',
      imgSrc: '/assets/img/foods/tartare.jpg',
      menuItems: [
        { name: 'Salmon Tartare', description: '', price: 8.90, ingredients: 'raw salmon, ponzu sauce, orange tobiko, micro herbs' },
        { name: 'Tuna Tartare', description: '', price: 9.80, ingredients: 'raw tuna, ponzu sauce, kimchi sauce, orange tobiko, micro herbs' },
        { name: 'Seabass Tartare', description: '', price: 10.20, ingredients: 'raw seabass, ponzu sauce, kimchi sauce, orange tobiko, micro herbs' },
      ]
    },
    {
      label: 'tataki',
      description: '',
      url: '/menu/alacarte/tataki',
      imgSrc: '/assets/img/foods/tataki.jpg',
      menuItems: [
        { name: 'Salmon Tataki', description: '', price: 7.80, ingredients: '5 pc, salmon sashimi, ponzu sauce, kimchi sauce, sesame seeds, micro herbs'},
        { name: 'Tuna Tataki', description: '', price: 9.80, ingredients: '5 pc, tuna sashimi, ponzu sauce, kimchi sauce, sesame seeds, micro herbs' },
      ]
    },
  ]



export default foodItems